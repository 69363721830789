
#services {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background-size: cover;
    -webkit-background-size: cover;
    width: 100%;
    height: 100vh;
    min-width: 320px;
    box-sizing: border-box;
}

#services h1 {
    padding-top: 10vh;
    padding-bottom: 2vh;
    font-size: 2rem;
    letter-spacing: 4px;
    font-weight: 600;
    text-align: center;
    line-height: 1.2;
}

.services-list {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.5rem;
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.service-item {
    width: 100%;
    max-width: 400px;
}

.services-tile {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 1.5rem;
    background: #f8f9fa;
    border-radius: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    cursor: pointer;
}

.services-tile:hover {
    transform: translateY(-5px) scale(1.05);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
}

.services-tile i {
    font-size: 3rem;
    margin-bottom: 1rem;
}

.service-name {
    font-size: 1.2rem;
    font-weight: 600;
}

@media (max-width: 660px) {
    .services-tile {
        padding: 1rem;
    }

    .services-tile i {
        font-size: 2.5rem;
    }

    .service-name {
        font-size: 1rem;
    }
}

@media (max-height: 800px) {
    #services {
        min-height: 800px;
    }

    .services-tile {
        padding: 1rem;
    }

    .services-tile i {
        font-size: 2.5rem;
    }

    .service-name {
        font-size: 1rem;
    }
}