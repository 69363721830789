#experience {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background-size: cover;
    -webkit-background-size: cover;
    width: 100%;
    height: 100vh;
    min-width: 320px;
    box-sizing: border-box;
}

#experience h1 {
    padding-top: 10vh;
    font-size: 2rem;
    letter-spacing: 4px;
    font-weight: 600;
    text-align: center;
    line-height: 1.2;
}

#experience h3 {
    font-size: 1.8rem;
    font-weight: 600;
}

#experience h4 {
    font-size: 1.5rem;
}

#experience h5 {
    font-size: 1.5rem;
}

#experience p {
    font-size: 1.2rem;
}

.experience-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2rem;
    text-align: center;
    height: 100%;
}

.experience-badge {
    font-size: 1rem !important;
    text-align: left !important;
    padding: 4px 6px !important;
    vertical-align: baseline;
    font-weight: lighter !important;
}

@media only screen and (max-width: 575px) {
    #experience h3 {
        font-size: 1.2rem;
    }

    #experience h4 {
        font-size: 1rem;
    }

    #experience h5 {
        font-size: 1rem;
    }

    #experience p {
        font-size: 0.8rem;
    }

    .experience-icon {
        font-size: 2rem;
    }

    .main-badge,
    .experience-badge {
        font-size: 0.625rem !important;
    }
}

@media only screen and (max-height: 800px) {
    #experience {
        min-height: 800px;
    }

    #experience h3 {
        font-size: 1.2rem;
    }

    #experience h4 {
        font-size: 1rem;
    }

    #experience h5 {
        font-size: 1rem;
    }

    #experience p {
        font-size: 0.8rem;
    }

    .experience-icon {
        font-size: 2rem;
    }

    .main-badge,
    .experience-badge {
        font-size: 0.625rem !important;
    }
}