nav {
	  width: 100vw;
	  position: fixed;
	  z-index: 10;
	  top: 0;
	  padding: 1.2em 0;
	  text-align: center;
}

nav span {
	  font-size: 2rem;
	  border-bottom: 1px solid transparent;
	  transition: border-bottom 0.2s ease;
	  transition-delay: 0.25s;
	  margin: 2%;
	  padding-bottom: 0.3em;
}

nav span:hover {
	  cursor: pointer;
}

nav span.activeClass {
	  border-bottom-color: var(--lightAccent);
}

@media only screen and (max-width: 575px) {
	  nav span {
		    font-size: 1rem;
    }
}

@media only screen and (max-height: 800px) {
  nav span {
		  font-size: 1rem;
	  }
}