// Theme Variables
$font-family: 'Roboto Mono', monospace;
$bg-color: #F7F6F2;
$font-color: #A63636;
$font-accent-color: #D3504A;
$bg-accent-color: #E4E1D4;

@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono&display=swap');

// Global Style
body, html {
  background-color: $bg-color;
}

// Nav Style 
nav {
  color: $bg-color;
  background-color: $font-color;
  font-family: $font-family;

  span:hover {
    border-bottom: 1px solid $font-accent-color;
  }
}

// Header Style
header {
  background-color: $bg-color;

  h1 {
    color: $font-color;
    font-family: $font-family;
  }

  .title-styles {
    color: $font-accent-color;
    font-family: $font-family;
  }


  .header-icon.visible {
    opacity: 0.8;
    box-shadow: 0 0 10px 4px $font-accent-color;
    border-color: $font-color;
  }

  .spinner {
    border: 4px solid $bg-accent-color;
    border-left-color: $font-color;
  
  }

  .header-icon-links {
    cursor: pointer;

    a, a:visited {
      color: $font-color;
    }

    a:hover, a:focus {
      color: $font-accent-color;
    }
  }
}

// About Style
#about {
  background-color: $bg-accent-color;

  h1 span {
    color: $font-color;
    font-family: $font-family;
  }

  .profile_pic span {
    background: $font-accent-color;
  }

  .card {
    background: $bg-accent-color;
    color: $font-color;
    font-family: $font-family;
  }

  .res-button a {
    border: 2px solid $font-accent-color;
    color: $font-color;
    font-family: $font-family;
  }

  .res-button a:hover {
    border: 2px solid $font-accent-color;
    color: $font-color;
    background-color: rgba($font-color, 0.1);
  }
}

// Experience Style
#experience {
  background: $bg-color;

  h1 span {
    color: $font-color;
    font-family: $font-family;
  }

  .experience-badge {
    background: #e4e1d4 !important;
    color: $font-color;
    font-family: $font-family;
  }

  .main-badge {
    background: #919191 !important;
  }

  .vertical-timeline::before {
    background: $font-color;
  }
}

// Projects Style
#projects {
  background-color: $bg-accent-color;

  .section-title {
    color: $font-color !important;
    font-family: $font-family;
  }

  .project-card {
    border-color: $font-color;
  }

  .project-title {
    color: $bg-color;
    font: $font-family;
  }

  .project-date {
    color: $bg-color;
    font: $font-family;
  }
  
  .project-description {
    color: $bg-color;
  }
}

.slider nav {
  color: $bg-color;
}

.modal-title,
.modal-description {
  font-family: $font-family;
}

.modal-inside .modal-content {
  background: $bg-color;
  border-color: $font-color;
}

.slider-image {
  border: 5px solid $font-color;
}

.single-image {
  border: 5px solid $font-color;
}

.modal-title {
  color: $font-color;
}

.awssld {
  border-color: $font-color;
}

.close-icon {
  color: $font-color;
}

// Skills Style
#skills {
  background-color: $bg-color;

  h1 {
    color: $font-color;
    font-family: $font-family;
  }
}

.skills-tile {
  background-color: $font-color;
  font-family: $font-family;

  i {
    color: $bg-color;
  }

  p {
    color: $bg-color;
    font-family: $font-family;
  }

  box-shadow: 0 0 5px $font-accent-color, 0 0 10px $font-accent-color;
}

// Services Style
#services {
  background-color: $bg-accent-color;

  h1 {
    color: $font-color;
    font-family: $font-family;
  }

  .services-tile {
    background-color: $font-color;
    font-family: $font-family;
    border: none;
    i {
      color: $bg-color;
    }

    p {
      color: $bg-color;
      font-family: $font-family;
    }

    &:hover {
      background-color: $font-accent-color;
    }
  }
}