#about {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background-size: cover;
    -webkit-background-size: cover;
    text-align: center;
    width: 100%;
    height: 100vh;
    min-width: 320px;
    box-sizing: border-box;
}

.center {
    display: flex;
    justify-content: center;
    align-items: center;
}

#about h1 {
    padding-top: 10vh;
    padding-bottom: 5vh;
    font-size: 2rem;
    letter-spacing: 4px;
    font-weight: 600;
    text-align: center;
    line-height: 1.2;
}

#about .profile_pic img {
    display: block;
    width: 18vw;
    height: 18vw;
    border-radius: 50%;
    object-fit: cover;
}

#about .profile_pic span {
    display: inline-block;
    margin: auto;
    padding: 5px;
    text-align: center;
    border-radius: 50%;
    text-decoration: none;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
    transition: all 0.2s linear;
    position: relative;
    margin-bottom: 3%;
}

#about .card {
    font-size: 1.5rem;
    text-align: center;
    line-height: 1.5;
    border: none;
    margin-bottom: 3%;
    padding: 10px;
}

#about .res-button a {
    display: inline-block;
    padding: 10px 20px;
    font-size: 1.2rem;
    text-align: center;
    text-decoration: none;
    border-radius: 8px;
    transition: background 0.3s ease, color 0.3s ease;
}

@media only screen and (max-width: 575px) {
    #about .profile_pic img {
        width: 120px;
        height: 120px;
    }

    #about .card {
        font-size: 1rem;
        line-height: 1.8;
    }

    #about .res-button a {
        font-size: 1rem;
        padding: 8px 16px;
        width: auto;
    }
}

@media only screen and (max-height: 800px) {
    #about {
        min-height: 800px;
    }

    #about .profile_pic img {
        width: 120px;
        height: 120px;
    }

    #about .card {
        font-size: 1rem;
        line-height: 1.8;
    }

    #about .res-button a {
        font-size: 1rem;
        padding: 8px 16px;
        width: auto;
    }
}