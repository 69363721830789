@import './Nav.scss';
@import './Header.scss';
@import './About.scss';
@import './Experience.scss';
@import './Projects.scss';
@import './Skills.scss';
@import './Services.scss';

html {
  font-size: 62.5%;
  -webkit-font-smoothing: antialiased;
  margin: 0;
  padding: 0;
  overflow-y: scroll;
  min-width: 100%;
}

body {
  margin: 0;
  overflow-x: hidden;
}


::-webkit-scrollbar {
  width: 0;
}

.aligner {
  display: flex;
  align-items: center;
  justify-content: center;
}

* {
  box-sizing: border-box;
}

@import './theme.scss';